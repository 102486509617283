// src/store.js
import { createStore } from 'redux';

// Simple reducer
const initialState = {
  example: 'Spend 3.1415 Tether, and you will see how many other curious folks did too! It is like a club, but cheaper and with way more people. Join the Pi-Party and watch the numbers roll in!',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

const store = createStore(reducer);

export default store;
